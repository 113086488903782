import React, { useState, useEffect } from "react";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ConfigProvider, Modal, Button } from "antd";
import "antd/dist/reset.css";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { authProtectedRoutes, publicRoutes } from "./routes/index";
import { store } from "./redux/config/store";
import theme from "./styles/theme";
import MyLayout from "components/common/layout/sideBar";
import { PowerBIProvider } from "components/waste_overview/powerBI/powerbiContext";
import ErrorFallback from "components/common/error";
import { ErrorBoundary } from "react-error-boundary";
import { msalInstance } from "../src/sso/msalInstance";

// const COOKIE_ASKING = 0;
// const COOKIE_ACCEPTED = 1;

function App() {
  // const [cookieState, setCookieState] = useState({
  //   permission: COOKIE_ASKING,
  //   accepted: false,
  // });

  // // Check if cookie consent was previously given
  // useEffect(() => {
  //   const storedState = localStorage.getItem("cookieState");
  //   if (storedState) {
  //     setCookieState(JSON.parse(storedState));
  //   }
  // }, []);

  // // Update local storage when consent changes
  // useEffect(() => {
  //   if (cookieState.permission !== COOKIE_ASKING) {
  //     localStorage.setItem("cookieState", JSON.stringify(cookieState));
  //   }
  // }, [cookieState]);

  return (
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ConfigProvider theme={theme}>
          <BrowserRouter>
            <MsalProvider instance={msalInstance}>
              <AuthenticatedTemplate>
                <PowerBIProvider>
                  <MyLayout>
                    <Routes>
                      {authProtectedRoutes.map((route, index) => (
                        <Route
                          key={index}
                          path={route.path}
                          element={route.element}
                        />
                      ))}
                    </Routes>
                  </MyLayout>
                </PowerBIProvider>
              </AuthenticatedTemplate>

              <UnauthenticatedTemplate>
                <Routes>
                  {publicRoutes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    />
                  ))}
                </Routes>

                {/* {cookieState.permission !== COOKIE_ACCEPTED && (
                  <Modal
                    open
                    footer={null}
                    centered
                    closable={false}
                    width={600}
                    bodyStyle={{ textAlign: "center", fontSize: 16 }}
                  >
                    <p>
                      This site uses cookies required for secure login and to ensure its
                      proper functioning. By signing in, you consent to the use of these
                      cookies.
                    </p>

                    <div style={{ marginTop: 20 }}>
                      <Button
                        type="default"
                        onClick={() =>
                          setCookieState({
                            permission: COOKIE_ACCEPTED,
                            accepted: false,
                          })
                        }
                        style={{ marginRight: 10 }}
                      >
                        Decline
                      </Button>
                      <Button
                        type="primary"
                        onClick={() =>
                          setCookieState({
                            permission: COOKIE_ACCEPTED,
                            accepted: true,
                          })
                        }
                      >
                        Accept
                      </Button>
                    </div>
                  </Modal>
                )}  */}
              </UnauthenticatedTemplate>
            </MsalProvider>
          </BrowserRouter>
        </ConfigProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
