/**
 * @fileoverview This file contains the layout component for the application, including a sidebar and header.
 * @author Sravani
 */
import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Avatar, Button, Layout, Menu, Select } from "antd"
import { Modal } from "antd"
import "./sideBar.css"
import { useMsal } from "@azure/msal-react"
import { useDispatch, useSelector } from "react-redux"
import {
  PoweroffOutlined,
  HomeFilled,
  RightOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons"
import {
  setReduxCurrency,
  setReduxUnit,
} from "../../../redux/reducer/currencySlice"
import { usePowerBI } from "components/waste_overview/powerBI/powerbiContext"
import { persistor } from "../../../redux/config/store"
import { initializeMSAL, trigerAccountCreation } from "sso/msalInstance"
import { getHeaders } from "services/utilService"
import Logo from "../../../assets/Logo-white-transparent-RGB 2.png"
import homeIcon from "../../../assets/homeIcon.svg"
import countryBenchmark from "../../../assets/countryBenchmark.svg"
import wasteOverview from "../../../assets/wasteOverview.svg"
import intervationCenter from "../../../assets/intervationCenter.svg"
import settingsIcon from "../../../assets/settingsIcon.svg"
import { closePopup } from "../../../redux/reducer/aiInterventionPopUpSlice"
import LogoImg from "../../../assets/Logo.png"

const { Header, Sider } = Layout
const { Content } = Layout

const currencyOptions = [
  { value: "Dollar", label: "Dollar $" },
  { value: "Euro", label: "Euro €" },
]

const unitOptions = [
  { value: "Millimeter", label: "Millimeter, Kgs" },
  { value: "inch", label: "Inches, Pounds" },
]
/**
 * @class MyLayout
 * @description This component renders the main layout with a sidebar, header, and content area.
 */
const MyLayout = props => {
  const { Option } = Select
  const { instance, accounts } = useMsal()
  const [selectedIcon, setSelectedIcon] = useState(null)
  const [isActive, setActive] = useState(false)
  const [activeItem, setActiveItem] = useState("")
  const [imageUrl, SetImageUrl] = useState(null)
  const fullName = accounts[0]?.name || "User"
  const firstName = fullName.split(" ")[1]
  const lastName = fullName.split(" ")[0]
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [powerBILink, setPowerBILink] = useState([])
  const [isExpanded, setIsExpanded] = useState(false)
  const { setPowerBILinkItem } = usePowerBI() // Access the setter function from context
  const dispatch = useDispatch()
  const currency = useSelector(state => state.currencySlice.currency)
  const unit = useSelector(state => state.currencySlice.unitOfMeasure)
  const [selectedCurrency, setSelectedCurrency] = useState(currency?.value)
  const [selectedUnit, setSelectedUnit] = useState(unit?.value)

  useEffect(() => {
    const initializeMSALInstance = async () => {
      await initializeMSAL()
      trigerAccountCreation()
    }

    initializeMSALInstance()
    fetchPowerBILinks()
    setIsExpanded(false)
    return () => {
      setIsExpanded(false)
    }
  }, [])

  const fetchPowerBILinks = async () => {
    try {
      // Perform the API call with the Authorization header
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/reports/getReports`,
        {
          method: "GET", // Or "POST" depending on your API
          headers: await getHeaders(),
        },
      )
      // Check if the response is successful
      if (!response.ok) {
        throw new Error("Failed to fetch Power BI links")
      }

      const data = await response.json()

      // Map the data into the desired format
      const updatedPowerBILinks = data.data.map((item, index) => ({
        key: index + 1,
        title: item.Title,
        icon: "WasteDrilldown", // Replace with actual icon if needed
        name: item.Name,
        path: item.Path,
        reportType: item.ReportType,
      }))

      // Update state with the fetched data
      setPowerBILink(updatedPowerBILinks)
      // setLoading(false); // Update loading state
    } catch (error) {
      console.error("Error fetching Power BI links:", error)
      // setLoading(false); // Ensure loading state is updated even in case of error
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  // Fetch the Power BI links dynamically from an API

  /**
   * @function getInitials
   * @description Extracts the initials from a full name.
   * @param {string} name - The full name of the user.
   * @returns {string} The initials of the user.
   */
  const getInitials = name => {
    const nameParts = name.split(" ")
    const firstNameInitial = nameParts[0]?.charAt(0).toUpperCase() || ""
    const lastNameInitial =
      nameParts.length > 1
        ? nameParts[1]?.charAt(0).toUpperCase()
        : firstNameInitial
    return `${firstNameInitial}${lastNameInitial}`
  }
  const initials = getInitials(fullName)

  /**
   * @function handleCurrencyChange
   * @description Handles the change of currency selection.
   * @param {string} name - The name of the currency.
   * @param {string} value - The selected currency value.
   */
  const handleCurrencyChange = (name, value) => {
    setActive(false)
    // setCurrency(value)
    dispatch(setReduxCurrency(value))
  }
  const [dropdownStates, setDropdownStates] = useState({
    currency: false,
    unit: false,
  })
  /**
   * @function handleChange
   * @description Handles the selection change in the dropdown.
   * @param {any} value - The selected dropdown value.
   */
  const handleChange = value => {
    onChange && onChange(name, value)
    setDropdownOpen(false)
  }
  /**
   * @function handleDropdownVisibleChange
   * @description Controls the visibility of the dropdown.
   * @param {boolean} open - The state indicating if the dropdown is open.
   */
  const handleDropdownVisibleChange = (type, open) => {
    setDropdownStates(prevState => ({
      ...prevState,
      [type]: open,
    }))
  }
  /**
   * Sets the page title and subtitle based on the current route.
   * @constant {string} title - The main title of the page.
   * @constant {string} subtitle - The subtitle of the page based on route.
   */
  const location = useLocation()

  let title = "Cost to Serve - Deep Dive Tool"
  let subtitle = ""

  switch (location.pathname) {
    case "/service":
      subtitle = "landing"
      break
    case "/country":
      subtitle = "Country KPI View"
      break
    case "/intervention_centre":
      title = "Intervention Center"
      subtitle = "Intervention Center"
      break
    // case "/registered_Intervention":
    //   subtitle = "Registered Intervention Center"
    //   break
    // case "/ai_suggestions":
    //   subtitle = "AI-Suggested Intervention Center"
    //   break
    case "/waste_overview":
      subtitle = "WASTE Overview"
      break
    case "/Country_Benchmarking":
      subtitle = "Country Benchmarking"
      break
    case "/waste_deepdive":
      subtitle = "WASTE Deep Dive"
      break
    default:
      const foundLink = powerBILink?.find(pb => pb.path === location.pathname)
      if (foundLink) {
        subtitle = foundLink.title
      } else {
        subtitle = "Overview"
      }
      break
  }
  /**
   * @function handleIconClick
   * @description Handles the icon selection event.
   * @param {any} icon - The selected icon.
   */
  const handleIconClick = icon => {
    setSelectedIcon(icon)
  }

  /**
   * @function handleLogout
   * @description Logs the user out of the application.
   * @param {string} logoutType - The type of logout to perform (popup or redirect).
   */
  const handleLogout = logoutType => {
    dispatch(setReduxCurrency({ value: "Dollar", label: "Dollar" }))
    dispatch(setReduxUnit({ value: "inch", label: "inch" }))
    dispatch(closePopup())
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: `${process.env.REACT_APP_SSO_REDIRECT_URI}`,
        mainWindowRedirectUri: "",
      })
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: `${process.env.REACT_APP_SSO_REDIRECT_URI}`,
      })
    }
    persistor.purge()
    dispatch({
      type: "RESET_TO_INITIAL",
      payload: "",
    })
    localStorage.removeItem("authUser")

    // Remove from localStorage
    localStorage.removeItem(`CTS - 2.0 ${process.env.REACT_APP_ENV}`)
  }

  /**
   * @function isActiveTab
   * @description Checks if the current path is active.
   * @param {string} path - The path to check against.
   * @returns {boolean} True if the path is active, false otherwise.
   */
  const isActiveTab = path => {
    return location.pathname.startsWith(path)
  }

  const handleSave = () => {
    dispatch(
      setReduxCurrency({
        key: selectedCurrency,
        value: selectedCurrency,
        children: selectedCurrency,
      }),
    )
    dispatch(setReduxUnit({ value: selectedUnit, label: selectedUnit }))
    setIsModalVisible(false)
  }

  return (
    <Layout className="layout-container">
      {location.pathname !== "/service" && (
        <Sider
          width={70}
          theme="light"
          style={{
            zIndex: isExpanded ? 1000 : 0, // Dynamically set zIndex
            transition: "width 0.2s ease-in-out, z-index 0.2s ease-in-out",
          }}
        >
        <div
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)", // Center the logo horizontally
            zIndex: 10, // Keep the logo on top of other content
          }}
        >
              <img
                src={Logo}
                alt="unileverlogo"
                loading="lazy"
                className={`sider-logo ${isExpanded ? "expanded" : ""}`}
              />
            </div>
    
        <div
          className={`sidebar ${isExpanded ? "expanded" : ""}`}
          onMouseEnter={() => setIsExpanded(true)}
          onMouseLeave={() => setIsExpanded(false)}
          style={{
            paddingTop: "68px", 
          }}
        >
          <Menu
              className="menu-bar"
              mode="vertical"
              defaultSelectedKeys={["1"]}
              selectedKeys={[location.pathname]}
            >
              <Menu.Item
                key="landing"
                className={`tabs ${isActiveTab("/service") ? "active-tab" : ""}`}
                onClick={() => handleIconClick("service")}
              >
                <Link to="/service">
                  <div>
                    <img
                      src={homeIcon}
                      alt="home"
                      loading="lazy"
                      style={{
                        paddingLeft: "10px",
                        width: "30px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                    {isExpanded && <p>Landing</p>}
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item
                key="intervention_center"
                className={`tabs ${isActiveTab("/intervention_centre") ? "active-tab" : ""}`}
                onClick={() => handleIconClick("intervention_centre")}
              >
                <Link to="/intervention_centre">
                  <img
                    src={intervationCenter}
                    alt="intervationCenter"
                    loading="lazy"
                    style={{
                      paddingLeft: "10px",
                      width: "30px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                  />
                  {isExpanded && <p>Intervention Centre</p>}
                </Link>
              </Menu.Item>

              <>
                {powerBILink.map(pb => (
                  <Menu.Item
                    key={pb.key}
                    className={`tabs ${isActiveTab(pb.path) ? "active-tab" : ""}`}
                    onClick={() => {
                      setSelectedIcon(pb.name)
                      setPowerBILinkItem(pb)
                    }}
                  >
                    <Link to={pb.path}>
                      {pb.title == "Waste Overview & Deep Dive" ? (
                        <img
                          src={wasteOverview} // Or use pb.icon if applicable
                          alt={pb.title}
                          loading="lazy"
                          style={{
                            paddingLeft: "10px",
                            width: "30px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <img
                          src={countryBenchmark} // Or use pb.icon if applicable
                          alt={pb.title}
                          loading="lazy"
                          style={{
                            paddingLeft: "10px",
                            width: "30px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                      {isExpanded && (
                        <p>
                          {pb.title == "Waste Overview & Deep Dive"
                            ? "Waste Overview"
                            : "Country Benchmarking"}
                        </p>
                      )}
                    </Link>
                  </Menu.Item>
                ))}
              </>

              <Menu.Item
                key="settings"
                className={`tabs ${isActiveTab("/#") ? "active-tab" : ""}`}
                onClick={showModal}
              >
                <img
                  src={settingsIcon}
                  alt="settings"
                  loading="lazy"
                  style={{
                    paddingLeft: "10px",
                    width: "30px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                />
                {isExpanded && <p>Settings</p>}
              </Menu.Item>
            </Menu>
          </div>
        </Sider>
      )}

      <Layout>
        {location.pathname !== "/service" &&
          location.pathname !== "/Country_Benchmarking" &&
          location.pathname !== "/Waste_Deep_Dive" && (
            <div className="header-container">
              <div className="profile-header">
                <div className="left-side">
                  <div className="title">{title}</div>
                  <div className="title-sub-title">
                    <HomeFilled /> Cost To Serve <RightOutlined /> {subtitle}
                  </div>
                </div>
                <div className="right-side">
                  {/* {!isActive && (
                <p
                  style={{
                    color: "#292929",
                    marginTop: "22px",
                    fontSize: "14px",
                    fontFamily: "Unilever Shilling",
                  }}
                  onClick={handleClick}
                >
                  Select Currency Type($)
                </p> */}
                  {/* )} */}
                  {/* {isActive && ( */}
                  {location.pathname !== "/ai_suggestions/options" && (
                    <div className="filter-section">
                      {/* <Select
                        className="filter-dropdown"
                        placeholder="Select here"
                        onChange={handleCurrencyChange}
                        value={currency}
                        suffixIcon={
                          dropdownOpen ? (
                            <img
                              src={uparrow}
                              alt="up Arrow"
                              style={{ width: "20px", height: "20px" }}
                            />
                          ) : (
                            <img
                              src={downarrow}
                              alt="down arrow"
                              style={{ width: "20px", height: "20px" }}
                            />
                          )
                        }
                      >
                        {currencyOptions.map(option => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select> */}
                    </div>
                  )}
                  {/* )} */}
                  <div>
                    <img
                      src={LogoImg}
                      alt="unileverlogo"
                      loading="lazy"
                      className="CTS-logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

        <Content
          className="before-login-main-content"
          onMouseEnter={() => setIsExpanded(false)}
        >
          {props.children}
        </Content>
      </Layout>

      <Modal
        title="Settings"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={600}
        className="settings-modal"
      >
        <div className="modal-content">
          <div className="dropdowns-container">
            <div className="dropdown-section">
              <label>Currency</label>
              <Select
                value={selectedCurrency}
                onChange={value => setSelectedCurrency(value)}
                style={{ width: "100%" }}
                suffixIcon={
                  dropdownStates.unit ? (
                    <CaretUpOutlined
                      style={{ color: "black", fontSize: "14px" }}
                    />
                  ) : (
                    <CaretDownOutlined
                      style={{ color: "black", fontSize: "14px" }}
                    />
                  )
                }
                onDropdownVisibleChange={open =>
                  handleDropdownVisibleChange("unit", open)
                }
              >
                {currencyOptions.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>

            {/* Unit of Measure Dropdown */}
            <div className="dropdown-section">
              <label>Unit of Measure</label>
              <Select
                value={selectedUnit}
                onChange={value => setSelectedUnit(value)}
                style={{ width: "100%" }}
                suffixIcon={
                  dropdownStates.currency ? (
                    <CaretUpOutlined
                      style={{ color: "black", fontSize: "14px" }}
                    />
                  ) : (
                    <CaretDownOutlined
                      style={{ color: "black", fontSize: "14px" }}
                    />
                  )
                }
                onDropdownVisibleChange={open =>
                  handleDropdownVisibleChange("currency", open)
                }
              >
                {unitOptions.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="profile">
            <div>
              <Avatar size="default" className="profile-picture">
                {initials}
              </Avatar>
            </div>
            <div className="profile-name">{firstName}</div>
            <PoweroffOutlined
              style={{
                fontSize: "20px",
                cursor: "pointer",
                color: "#292929",
              }}
              onClick={() => handleLogout("popup")}
            />
          </div>

          <div className="modal-buttons" style={{ textAlign: "right" }}>
            <Button onClick={handleCancel} style={{ marginRight: "10px" }}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </div>
        </div>
      </Modal>
    </Layout>
  )
}

export default MyLayout