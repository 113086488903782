/**
 * @fileoverview Authentication component for user login
 * @author Sushma
 */
import { useMsal } from "@azure/msal-react"
import { Button, Card, Typography, theme } from "antd"
import React from "react"
import loginBG from "../../assets/img/background.svg"
import UniopsLogo from "../../assets/Uniops Unilever Blue Logo.png";
import { loginRequest } from "../../sso/authConfig"
import styles from "./login.module.css"
import { appInsights } from "services/appInsights";
const { Title, Text } = Typography

/**
 * Authentication component that handles user login via MSAL.
 * @function
 * @returns {JSX.Element} The rendered Authentication component.
 */
function Authentication() {
  const { instance, inProgress,accounts } = useMsal()
  const email = accounts[0]?.username
  const {
    token: { colorUnilever },
  } = theme.useToken()

  /**
  * Handles the login process using MSAL's popup method.
  * @function
  * @param {object} instance - The MSAL instance for authentication.
  * @returns {void} No return value.
  */
  //Function used to Logout using MSAL.
  function handleLogin(instance) {
    instance.loginPopup(loginRequest).catch(e => {
      console.error(e)
    })
    if(process.env.REACT_APP_ENV =="dev"||process.env.REACT_APP_ENV =="prod"){
    appInsights.setAuthenticatedUserContext(email);
  }}

  return (
    <div
      style={{
        height: "100vh",
        background: `url(${loginBG})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={styles.cardWrapper}>
        {" "}
        <Card bordered={false} className={`${styles.recomcard}`}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "end", padding: "20px 0" }}
            >
              <img src={UniopsLogo} alt="Unilever" loading="lazy" height={50} style={{ color: "blue" }} />
            </div>

            <div
              style={{
                padding: "28px 0",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Text style={{ color: colorUnilever, fontSize: 18, fontFamily: "Unilever Shilling" }}>
                Welcome to
              </Text>
              <Title level={2} style={{ marginTop: 0, color: colorUnilever, fontSize: "2.5rem", fontFamily: "Unilever Shilling" }}>
                Cost to Serve
              </Title>
            </div>

            <div style={{ padding: "28px 0" }}>
              <Button
                type="primary"
                style={{ backgroundColor: colorUnilever, cursor: "pointer" }}
                onClick={() => handleLogin(instance)}
                loading={inProgress === "login"}
              >
                Sign in with Unilever ID
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Authentication
