import { getIdToken } from "sso/msalInstance"

/**
 * Constructs headers for API requests.
 * @returns {Object} An object containing necessary headers for API requests.
 */
export const getHeaders = async () => {
  const idtoken = await getIdToken()
  return {
    authorization: idtoken,
    "Content-Type": "application/json",
  }
}

/**
 * Formats API response and handles errors.
 * @param {Response} apiResponse - The response from the API.
 * @returns {Object} An object containing success status, data, and error information.
 */
export const responseFormatter = async apiResponse => {
  if (!apiResponse.ok) {
    // logout
    if (apiResponse.status === 440) {
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
    }

    return {
      success: false,
      data: null,
      error: {
        message: `An error has occured: ${apiResponse.status}`,
      },
    }
  }

  const data = await apiResponse.json()

  return {
    success: true,
    data: data,
    error: null,
  }
}

/**
 * Generates options for dropdowns based on the current date.
 * @returns {Array} An array of objects containing label and value for each option.
 */
export const getUTFOptions = () => {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()
  const dataCurrentYear = []
  const dataMiddleYears = []

  for (let i = currentMonth + 1; i > 0; i--) {
    dataCurrentYear.push({
      label: `${i} ${currentYear}`,
      value: `${i} ${currentYear}`,
    })
  }

  for (let i = currentYear - 1; i > 2021; i--) {
    for (let j = 12; j > 0; j--) {
      dataMiddleYears.push({
        label: `${j} ${i}`,
        value: `${j} ${i}`,
      })
    }
  }

  const data2021 = [
    { label: "12 2021", value: "12 2021" },
    { label: "11 2021", value: "11 2021" },
    { label: "10 2021", value: "10 2021" },
    { label: "9 2021", value: "9 2021" },
    { label: "8 2021", value: "8 2021" },
  ]

  return [...dataCurrentYear, ...dataMiddleYears, ...data2021]
}

/**
 * Downloads a given data as a CSV file.
 * @param {Array} data - The data to be downloaded as CSV.
 * @param {string} filename - The name of the downloaded CSV file.
 */
export const downloadCSV = (data, filename) => {
  const csvData = Papa.unparse(data)
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url
  a.download = filename + ".csv"
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

/**
 * Converts a number to currency format.
 * @param {number} value - The value to be converted.
 * @param {string} [format='EUR'] - The currency format (default is 'EUR').
 * @returns {string} The value formatted as currency.
 */
export const convertToCurrencyFormat = (value, format = "EUR") => {
  if (isNaN(value)) {
    return value
  }

  return new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: format,
  }).format(value)
}

/**
 * Checks if the current environment is a pilot.
 * @returns {boolean} True if in pilot mode, otherwise false.
 */
export const isPilot = () => {
  return true
  return window.location.pathname.startsWith("/pilot/")
}

/**
 * Gets the correct path prefix based on the current URL.
 * @param {string} pathname - The pathname to be prefixed.
 * @returns {string} The full pathname with prefix if in pilot mode.
 */
// to get the url for pilot
export const getPathPrefix = pathname => {
  if (window.location.pathname.startsWith("/pilot/")) {
    return "/pilot" + pathname
  }

  return pathname
}

/**
 * Formats a number of days into a string representation of years, weeks, and days.
 * @param {number} numDays - The number of days to format.
 * @returns {string} A formatted string representing the time.
 */
export const formatDays = numDays => {
  const years = Math.floor(numDays / 365)
  const remainingDaysWithoutYears = numDays % 365
  const weeks = Math.floor(remainingDaysWithoutYears / 7)
  const remainingDays = remainingDaysWithoutYears % 7

  let result = ""

  if (years > 0) {
    result += `${years} year${years > 1 ? "s" : ""} `
  }

  if (weeks > 0) {
    result += `${weeks} week${weeks > 1 ? "s" : ""} `
  }

  if (remainingDays > 0) {
    result += `${remainingDays} day${remainingDays > 1 ? "s" : ""}`
  }

  return result.trim()
}

/**
 * Removes duplicate objects from an array based on a specified property.
 * @param {Array} array - The array to process.
 * @param {string} property - The property to check for duplicates.
 * @returns {Array} A new array with duplicates removed.
 */
export function removeDuplicates(array, property) {
  const seen = new Set()
  return array.filter(obj => {
    const propertyValue = obj[property]
    if (!seen.has(propertyValue)) {
      seen.add(propertyValue)
      return true
    }
    return false
  })
}

/**
 * Alternates results from an array of objects by grouping them based on PlantCode and PlantName.
 * @param {Array} data - The array of objects to process.
 * @returns {Array} An array with grouped objects.
 */
export function alterateAlternativeResult(data) {
  return data.reduce((acc, obj) => {
    let existingEntry = acc.find(
      entry =>
        entry.PlantCode === obj.PlantCode && entry.PlantName === obj.PlantName,
    )

    if (existingEntry) {
      existingEntry.MatchBrand.push(obj.MatchBrand)
    } else {
      acc.push({ ...obj, MatchBrand: [obj.MatchBrand] })
    }

    return acc
  }, [])
}
