import { createSlice } from '@reduxjs/toolkit';

const aiInterventionPopUpSlice = createSlice({
  name: 'popup',
  initialState: {
    isOpen: false,
    popupData: null, 
  },
  reducers: {
    openPopup: (state, action) => {
      state.isOpen = true;
      state.popupData = action.payload;
    },
    closePopup: (state) => {
      state.isOpen = false;
      state.popupData = null;
    },
  },
});

export const { openPopup, closePopup } = aiInterventionPopUpSlice.actions;
export default aiInterventionPopUpSlice.reducer;
