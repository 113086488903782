import React, { Suspense } from "react"
import Authentication from "components/authentication"
import { Navigate,  } from "react-router-dom"

const SummaryPage = React.lazy(()=> import("../components/waste_overview/deep_dive/design_to_distribute/summary/summary"))
const Country = React.lazy(()=> import("../components/waste_overview/powerBI/country"))

const Service = React.lazy(() => import("../components/service/service"))
const WasteOverview = React.lazy(
  () => import("../components/waste_overview/deep_dive"),
)

const Summary = React.lazy(
  () =>
    import(
      "../components/waste_overview/deep_dive/design_to_distribute/summary/summary"
    ),
)
const InterventionCenter = React.lazy(
  () => import("../components/intervention_centre"),
)
const RegisteredInterventionCenter = React.lazy(
  () => import("../components/registered_intervention/registeredIntervention"),
)
const AISuggestions = React.lazy(
  () => import("../components/ai_intervention/aiIntervention"),
)
const AiOptions = React.lazy(
  () => import("../components/ai_intervention/ai_options/aiOptions"),
)
const PowerBIUI = React.lazy(
  () => import("../components/waste_overview/powerBI/index"),
)
const RegisteredOptions = React.lazy(
  () =>
    import(
      "../components/registered_intervention/registered_options/registeredOptions"
    ),
)

const authProtectedRoutes = [
  {
    path: "/service",
    element: (
      <React.Suspense fallback={null}>
        <Service />
      </React.Suspense>
    ),
    breadcrumb: {
      label: "Service Command Center",
    },
  },
  {
    path: "/intervention_centre",
    element: (
      <React.Suspense fallback={null}>
        <InterventionCenter />
      </React.Suspense>
    ),
    breadcrumb: {
      label: "Intervention Center",
    },
  },
  {
    path: "/registered_Intervention/option/:InterventionRegistration_ID/:registeredBy",
    element: (
      <React.Suspense fallback={null}>
        <RegisteredOptions />
      </React.Suspense>
    ),
    breadcrumb: {
      label: "Registered Options",
    },
  },
  {
    path: "/registered_Intervention",
    element: (
      <React.Suspense fallback={null}>
        <RegisteredInterventionCenter />
      </React.Suspense>
    ),
    breadcrumb: {
      label: "Registered InterventionCenter",
    },
  },
  {
    path: "/ai_suggestions",
    element: (
      <React.Suspense fallback={null}>
        <AISuggestions />
      </React.Suspense>
    ),
    breadcrumb: {
      label: "AI Suggestions",
    },
  },
  {
    path: "/ai_suggestions/options",
    element: (
      <React.Suspense fallback={null}>
        <AiOptions />
      </React.Suspense>
    ),
    breadcrumb: {
      label: "AI Options",
    },
  },
  {
    path: "/waste_overview/:path",
    element: (
      <React.Suspense fallback={null}>
        <WasteOverview />
      </React.Suspense>
    ),
    breadcrumb: {
      label: "Waste Overview",
    },
  },
  {
    path: "/waste_overview/densityLoss/:path",
    element: (
      <React.Suspense fallback={null}>
        <WasteOverview />
      </React.Suspense>
    ),
    breadcrumb: {
      label: "Waste Overview",
    },
  },
  {
    path: "/Waste_Deep_Dive",
    element: (
      <React.Suspense fallback={null}>
        <PowerBIUI />
      </React.Suspense>
    ),
    breadcrumb: {
      label: "Waste Overview",
    },
  },
  {
    path: "/Country_Benchmarking",
    element: (
      <React.Suspense fallback={null}>
        <Country />
      </React.Suspense>
    ),
    breadcrumb: {
      label: "Country",
    },
  },
  {
    path: "/waste_overview/summary",
    element: (
      <React.Suspense fallback={null}>
        <SummaryPage />
      </React.Suspense>
    ),
    breadcrumb: {
      label: "Summary",
    },
  },
  {
    path: "/",
    element: <Navigate to="/service" replace />,
    exact: true,
  },
  {
    path: "*",
    element: <Navigate to="/service" replace />,
    exact: true,
  },
]

const publicRoutes = [
  {
    path: "/",
    element: <Authentication />,
  },
  {
    path: "/login",
    element: <Authentication />,
  },
  {
    path: "*",
    element: <Authentication />,
  },
]

export { publicRoutes, authProtectedRoutes }
