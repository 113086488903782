import { createSlice } from "@reduxjs/toolkit"

/**
 * @fileoverview This file defines a Redux slice for managing selected interventions in the application state.
 * @author Sushma
 */
const initialState = {
  selectedIntervention: [],
  interventionActiveTabKey: "ai",
  interventionDetailsActiveTabKey: "simulation",
}

const interventionSlice = createSlice({
  name: "interventionSlice",
  initialState,
  reducers: {
    /**
     * Adds a selected intervention to the state.
     * The new intervention replaces any existing one in the array.
     * @param {Object} state - The current state of the slice.
     * @param {Object} action - The action payload containing the selected intervention.
     */
    addselectedIntervention: (state, action) => {
      state.selectedIntervention = [action.payload]
    },
    /**
     * Removes the selected intervention based on the key from the state array.
     * @param {Object} state - The current state of the slice.
     * @param {Object} action - The action payload containing the key of the intervention to remove.
     */
    removeselectedIntervention: (state, action) => {
      state.selectedIntervention = state.selectedIntervention.filter(
        row => row.key !== action.payload,
      ) // Correctly remove row from array
    },
    setInterventionActiveTabKey: (state, action) => {
      state.interventionActiveTabKey = action.payload
    },
    setInterventionDetailsActiveTabKey: (state, action) => {
      state.interventionDetailsActiveTabKey = action.payload
    },
  },
})

export const {
  addselectedIntervention,
  removeselectedIntervention,
  setInterventionActiveTabKey,
  setInterventionDetailsActiveTabKey,
} = interventionSlice.actions
export default interventionSlice.reducer
