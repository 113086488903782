const theme = {
  components: {
    Select: {
      fontSize: 12,
      optionFontSize: 11,
      fontSizeIcon: 10,
      colorBorder: "#C7C7C7",
      colorBgContainerDisabled: "transparent",
      colorTextDisabled: "rgba(0, 0, 0, 0.88)",
    },
    Button: {
      borderRadius: 0,
      borderRadiusSM: 0,
      borderRadiusLG: 0,
      fontSize: 14,
      fontSizeSM: 12,
      fontSizeLG: 16,
      colorPrimary: "#005eef",
      colorPrimaryHover: "#0056da",
      colorPrimaryActive: "#0056da",
    },
    Radio: {
      borderRadiusSM: 4,
      borderRadius: 4,
      colorBorder: "#424242",
      colorPrimary: "#424242",
    },
    Checkbox: {
      colorBorder: "#424242",
      colorPrimary: "#424242",
    },
    Form: {
      fontSize: 11,
    },
    Table: {
      fontSize: 12,
      fontSizeIcon: 10,
    },
    Card: {
      paddingLG: 16,
    },
    Tabs: {
      itemActiveColor: "#005eef",
      fontSize: 16,
      fontFamily: "Unilever Shilling",
    },
    Segmented: {
      itemSelectedBg: "rgb(0,94,239)",
      itemSelectedColor: "#ffffff",
    },
  },
  token: {
    commodityColor: "#E7EEFF",
    plmColor: "#F0EDFB",
    componentColor: "#FFEBFB",
    activeText: "#292929",
    inActiveText: "#7D7D7D",
    activeTextWeight: 600,
    inActiveTextWeight: 400,
    bgColor: "#FFFFFF",
    disableBgColor: "#F5F5F5",
    colorPrimary: "#2435BF",
    colorUnilever: "#005eef",
    fontFamily: `Unilever Shilling, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
    borderRadius: 1,
    fontSize: 12,
    fontSizeSM: 11,
    fontSizeLG: 14,
    fontSizeXL: 16,
    fontSizeHeading1: 30,
    fontSizeHeading2: 24,
    fontSizeHeading3: 20,
    fontSizeHeading4: 18,
    fontSizeHeading5: 16,
  },
}

export default theme
