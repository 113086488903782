import { createSlice } from '@reduxjs/toolkit';

const riInterventionPopUpSlice = createSlice({
  name: 'modal',
  initialState: {
    isOpen: false,
    popupData: null, 
  },
  reducers: {
    openModal: (state, action) => {
      state.isOpen = true;
      state.popupData = action.payload;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.popupData = null;
    },
  },
});

export const { openModal, closeModal } = riInterventionPopUpSlice.actions;
export default riInterventionPopUpSlice.reducer;


