import {
  PublicClientApplication,
  EventType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser"
import { msalConfig } from "./authConfig"

/**
 * Initializes the MSAL instance for authentication.
 * @constant msalInstance
 * @type {PublicClientApplication}
 */
export const msalInstance = new PublicClientApplication(msalConfig)

/**
 * Function to ensure that the MSAL instance is initialized before using it.
 */
export const initializeMSAL = async () => {
  try {
    // Ensure the MSAL instance is initialized
    await msalInstance.initialize()
  } catch (error) {
    console.error("Error initializing MSAL:", error)
  }
}

// Now you can call any MSAL API after initialization

/**
 * Retrieves all accounts and sets the first account as the active account if available.
 * @constant accounts
 * @type {Array}
 */
let accounts = []
export const trigerAccountCreation = () => {
  accounts = msalInstance.getAllAccounts()
  triggerSetActiveAccount()
}
const triggerSetActiveAccount = () => {
  if (accounts.length > 0) {
    const activeAccount = accounts[0] // Set the first available account
    msalInstance.setActiveAccount(activeAccount)
    // Store only the active account info in localStorage for simplicity
    localStorage.setItem("authUser", JSON.stringify(activeAccount))
    // Call the function once during initialization
    // handleTokenExpiry();
  }
}

// Handle token acquisition after initialization
msalInstance.addEventCallback(async event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)

    // Token request object with the required scopes
    const tokenRequest = {
      scopes: ["user.read", "openid", "profile"],
    }

    try {
      // Check for an existing valid token in cache
      const response = await msalInstance.acquireTokenSilent(tokenRequest)
      const access_token = response.accessToken
      const id_token = response.idToken

      // Store the new tokens and user information
      localStorage.setItem("authUser", JSON.stringify(response))

      return {
        userAccessToken: access_token,
        userIdToken: id_token,
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          const response = await msalInstance.acquireTokenPopup(tokenRequest)
          const access_token = response.accessToken
          const id_token = response.idToken

          // Store the new tokens and user information
          localStorage.setItem("authUser", JSON.stringify(response))

          return {
            userAccessToken: access_token,
            userIdToken: id_token,
          }
        } catch (popupError) {
          console.error("Popup token acquisition failed:", popupError)
        }
      } else {
        console.error("Silent token acquisition failed:", error)
      }
    }
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    console.log("Login failed:", JSON.stringify(event)) // More descriptive error logging
  }
})

// Function to get the ID token
export const getIdToken = async () => {
  const account = msalInstance.getActiveAccount()
  const request = {
    scopes: ["user.read", "openid", "profile"], // Adjust the scopes based on your requirements
    forceRefresh: true,
    account: account,
  }

  try {
    const response = await msalInstance.acquireTokenSilent(request)
    return response.idToken
  } catch (error) {
    console.error("Error acquiring token silently:", error)
    if (error instanceof InteractionRequiredAuthError) {
      try {
        const response = await msalInstance.acquireTokenPopup(request)
        return response.idToken
      } catch (redirectError) {
        console.error("Error acquiring token interactively:", redirectError)
      }
    }
  }
}

