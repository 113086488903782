/** ADD TO CART*/

export const SELECTED_INTERVENTION = "SELECTED_INTERVENTION"
export const ADD_STIMULATION_TO_CART = "ADD_STIMULATION_TO_CART"
export const SELECTED_FILTERAI= "SELECTED_FILTERAI"
export const SELECTED_FILTERRI= "SELECTED_FILTERRI"
export const SELECTED_FILTERDENSITY= "SELECTED_FILTERDENSITY"
export const SELECTED_FILTERINTERNAL="SELECTED_FILTERINTERNAL"

export const DELETE_INTERVENTION_TO_CART = "DELETE_INTERVENTION_TO_CART"
export const DELETE_STIMULATION_TO_CART = "DELETE_STIMULATION_TO_CART"
export const RESET_TO_INITIAL = "RESET_TO_INITIAL"
export const SELECT_DENSITY_LOSS = "SELECT_DENSITY_LOSS"
export const SELECTED_FILTERHEIGHT = "SELECTED_FILTERHEIGHT"
export const RESET_FILTERHEIGHT = "RESET_FILTERHEIGHT"
export const SELECTED_HEIGHT_FILTEROVERVIEW = "SELECTED_HEIGHT_FILTEROVERVIEW"
export const SELECTED_DENSITY_FILTEROVERVIEW = "SELECTED_DENSITY_FILTEROVERVIEW"
export default {
  SELECTED_INTERVENTION,
  SELECTED_FILTERAI,
   SELECTED_FILTERRI,
   SELECTED_FILTERDENSITY,
   SELECTED_FILTERINTERNAL,
  ADD_STIMULATION_TO_CART,
  DELETE_INTERVENTION_TO_CART,
  DELETE_STIMULATION_TO_CART,
  RESET_TO_INITIAL,
  SELECT_DENSITY_LOSS,
  SELECTED_FILTERHEIGHT,
  RESET_FILTERHEIGHT,
  SELECTED_HEIGHT_FILTEROVERVIEW,
  SELECTED_DENSITY_FILTEROVERVIEW
}
